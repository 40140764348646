<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="mb-2">
          <h3>
            รายละเอียดค้างชำระ
          </h3>
        </div>
        <div v-if="dataCustomer">
          <div class="row ">
            <div class="col-12 col-sm-6 col-xl-5 text-center mb-2">
              <div class="mb-1">
                <b-img
                  rounded="circle"
                  alt="Circle image"
                  :src="$baseURL+dataCustomer.pic"
                  style="max-width:209px;width:100%"
                  @error="setAltImg"
                />

              </div>
            </div>
            <div class="col-12 col-sm-6 col-xl-7 ">
              <div class="row">
                <div class="col-4">
                  <p>รหัสลูกค้า</p>
                </div>
                <div class="col-7">
                  <p>{{ dataCustomer.code }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <p>ชื่อ</p>
                </div>
                <div class="col-7">
                  <p>  {{ dataCustomer.firstName }} &nbsp;{{ dataCustomer.lastName }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <p>เบอร์โทร</p>
                </div>
                <div class="col-7">
                  <p>{{ dataCustomer.telephoneNumber }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <p>ประเภท</p>
                </div>
                <div class="col-7">
                  <div class="d-flex">
                    <p class="mr-1">
                      {{ dataCustomer.type === 'U'?'ลูกค้าทั่วไป': 'ตัวแทน' }}
                    </p>
                    <p v-if="dataCustomer.type === 'V'">
                      {{ `(${dataCustomer.country.thName})` }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <p>ค้างชำระ</p>
                </div>
                <div class="col-7">
                  <p class="text-danger">
                    {{ sumTotalOverdue | toCurrency }} &nbsp;บาท
                  </p>
                </div>
              </div>
            </div>
            <!-- <table>
                <thead>
                  <tr>
                    <th style="min-width:85px; width:30%;" />
                    <th style="width:70%;" />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="pr-1 mb-2">
                      รหัสลูกค้า
                    </td>
                    <td colspan="2">
                      {{ dataCustomer.code }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pr-1">
                      ชื่อ
                    </td>
                    <td colspan="2">
                      {{ dataCustomer.firstName }} &nbsp;{{ dataCustomer.lastName }}
                    </td>
                  </tr>
                  <tr class="mb-3">
                    <td class="pr-1">

                      เบอร์โทร

                    </td>
                    <td>
                      {{ dataCustomer.telephoneNumber }}
                    </td>
                  </tr>
                  <tr class="mb-1">
                    <td class="pr-1">
                      ประเภท
                    </td>
                    <td>
                      {{ dataCustomer.type === 'U'?'ลูกค้าทั่วไป': 'ตัวแทน' }}
                    </td>
                  </tr>
                  <tr />
                </tbody>
              </table> -->
          </div>
        </div>
      </div>
      <b-table
        striped
        hover
        responsive
        class="type-relative"
        :per-page="inputsnode.perPage"
        :current-page="inputsnode.currentPage"
        :items="listOrder"
        :fields="fields"
        :filter="searchTerm"
        show-empty
        @filtered="onFiltered"
      >

        <template #cell(date)="data">
          <p>{{ data.item.created_at | formatDateSort }}</p>
        </template>
        <template #cell(price)="data">
          <p>{{ data.item.totalPrice.$numberDecimal | toCurrency }}</p>
        </template>
        <template #cell(status)="data">
          <p class="text-danger">
            รอการชำระ
          </p>
        </template>
        <template #cell(button)="data">
          <div class="d-flex">
            <div
              @click="onClickManagement(data.item._id)"
            >    <b-avatar
                   size="3em"
                   class="avatar-border-2 box-shadow-1"
                   variant="primary"
                   style="margin: 0px 3px;cursor:pointer"
                 >  <font-awesome-icon
                   :id="`tooltip-target-1${data.item._id}`"
                   style="width: 20px;"
                   :icon="['fa', 'list']"
                 /></b-avatar>
              <b-tooltip
                :target="`tooltip-target-1${data.item._id}`"
                triggers="hover"
                placement="left"
                offset="10"
              >
                จัดการ
              </b-tooltip>

            </div>
            <div
              @click="onClickPayment(data.item._id)"
            >
              <b-avatar
                size="3em"
                style="margin: 0px 3px;cursor:pointer"
                class="avatar-border-2 box-shadow-1"
                variant="info"
              >
                <font-awesome-icon
                  :id="`tooltip-target-2${data.item._id}`"
                  style="width: 20px;cursor:pointer"
                  :icon="['fa', 'money-bill-1']"
                />
              </b-avatar>
              <b-tooltip
                :target="`tooltip-target-2${data.item._id}`"
                triggers="hover"
                placement="right"
              >
                ชำระ
              </b-tooltip>
            </div>
          </div>
        </template>
        <template #empty>
          <div class="col-md-12 text-center m-1">
            <h4>ไม่พบข้อมูลการขาย</h4>
          </div>
        </template>
        <template #emptyfiltered>
          <div class="col-md-12 text-center m-1">
            <h4>ไม่พบข้อมูลการขาย</h4>
          </div>
        </template>
      </b-table>
      <b-card-body
        class="d-flex justify-content-between flex-wrap pt-0 mt-2"
      >

        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="inputsnode.perPage"
            size="sm"
            inline
            :options="inputsnode.pageOptions"
          />
        </b-form-group>

        <div>
          <b-pagination
            v-model="inputsnode.currentPage"
            :total-rows="inputsnode.totalRows"
            :per-page="inputsnode.perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BImg,
  BAvatar,
  BButtonGroup, BTooltip,
  BRow, BCol, BTable, BFormGroup, BFormSelect, BPagination, BModal, BButton, VBModal, BCardBody, BFormInput, BFormCheckbox, BBadge
  , BForm
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DatePicker from '@/views/components/production/datePicker.vue'
import PlaceHolder from '@/assets/images/production/user.png'

export default defineComponent({
  name: 'agent_order_overdue',

  components: {
    BImg,
    BTooltip,
    BAvatar,
    BButtonGroup,
    BRow,
    BCol,
    BTable,
    BButton,
    BFormSelect,
    BPagination,
    BCardBody,
    BFormGroup,
    BCardCode,
    BModal,
    VBModal,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    required,
    DatePicker,
    BFormCheckbox,
    BBadge
  },
  computed: {
    sumTotalOverdue () {
      let sum = 0
      this.listOrder.forEach(data => {
        sum += parseFloat(data.totalPrice.$numberDecimal)
      })
      return sum
    }
  },
  watch: {

  },
  created () {
    this.getListOrder()
  },
  methods: {
    setAltImg (e) {
      e.target.src = PlaceHolder
    },
    onFiltered (filteredItems) {
      this.inputsnode.totalRows = filteredItems.length
      this.inputsnode.currentPage = 1
    },
    getListOrder () {
      this.$store.dispatch('customer/getagentoverduebyid', { code: this.$route.params.code }).then(res => {
        if (res.data.success) {
          this.listOrder = res.data.items[0]
          this.inputsnode.totalRows = this.listOrder.length
          this.dataCustomer = res.data.items[1][0]
          console.log(this.dataCustomer)
        }
      })
    },
    onClickManagement (code) {
      this.$router.replace({ path: `/ordermanagement/${code}` })
    },
    onClickPayment (code) {
      this.$router.push({ path: `/orderpayment/${code}/add` })
    }
  },
  data () {
    return {
      dataCustomer: null,
      listOrder: [],
      searchTerm: '',
      fields: [
        {
          key: 'code', label: 'เลขที่', thStyle: 'min-width: 170px', tdClass: 'cursordefault'
        },
        {
          key: 'date', label: 'วันที่-เวลา', thStyle: 'min-width: 160px', tdClass: 'cursordefault p-0'
        },
        {
          key: 'price', label: 'ราคา', thStyle: 'min-width: 140px', tdClass: 'text-right cursordefault', thClass: 'text-right'
        },
        {
          key: 'status', label: 'สถานะ', thStyle: 'min-width: 130px', tdClass: 'cursordefault'
        },
        { key: 'button', label: '', thStyle: 'min-width: 140px', tdClass: 'text-center cursordefault p-0', thClass: 'text-center' }

      ],
      inputsnode: {
        perPage: 5,
        pageOptions: [5, 10, 15],
        totalRows: 0,
        itemsPerPage: 10,
        currentPage: 1,
        startItem: 0,
        endItem: 10
      }

    }
  }
})
</script>
<style >
.modal-title {
  color: white;
}
.cursordefault{
  cursor: default;
}
.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  /* color: #fff; */
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 105%;
}
.hoverIc :hover{
  color: #7367f0;

}

/* .tooltipList:hover .tooltiptext {
  visibility: visible;
} */
</style>
